.wrapper {
    display: flex;
    justify-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    align-items: center;
    height: 100vh;
}

.content {
    display: flex;
    justify-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    align-items: center;
    font-family: "Sorts Mill Goudy", serif;
    font-weight: 500;
    font-style: normal;
    font-size: 30px;
}

.social-link {
    display: flex;
    text-align: center;
    align-items: center;
    margin-bottom: 5px;
}

.social-icon {
    margin-right: 20px;
    height: 30px;
    width: 30px;
}

.social-text {
    font-size: 2.5rem;
    color: #ff0101;
    text-decoration: none;
}

.works {
    position: absolute;
    bottom: -20px;
    left: auto;
    right: auto
}

.logo-wrapper {
    position: relative;
    text-align: center;
    display: flex;
    place-content: center
}