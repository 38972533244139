@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
html {
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-sizing: border-box;
    line-height: 1.15;
    background-color: var(--background-site);
    font-size: 62.5%;
    font-family: Inter, 'system-ui', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol';
}
*,
*::before,
*::after {
    box-sizing: inherit;
}
body {
    background-color: var(--background-site);
    color: var(--text-high-contrast);
    font-size: 1.6rem;
    line-height: 1.65;
}
.theme-light {
    --brand-primary: rgb(47, 112, 193);
    --brand-secondary: rgb(116, 97, 195);
    --brand-alternative: rgb(19, 120, 134);
    --background-site: rgb(249, 249, 249);
    --background-code: rgb(244, 244, 244);
    --text-body: rgb(54, 49, 61);
    --text-comment: rgb(99, 94, 105);
    --text-high-contrast: rgb(49, 49, 49);
    --text-medium-contrast: rgb(99, 94, 105);
    --text-low-contrast: rgb(116, 109, 118);
    --detail-high-contrast: rgb(192, 192, 192);
    --detail-medium-contrast: rgb(234, 234, 234);
    --detail-low-contrast: rgb(240, 240, 242);
    --admonition-note: rgb(46, 109, 188);
    --admonition-warning: rgb(255, 196, 9);
    --admonition-danger: rgb(220, 38, 38);
    --brand-primary-rgb-value: 47, 112, 193;
    --brand-secondary-rgb-value: 116, 97, 195;
    --brand-alternative-rgb-value: 19, 120, 134;
    --background-site-rgb-value: 249, 249, 249;
    --background-code-rgb-value: 244, 244, 244;
    --text-body-rgb-value: 54, 49, 61;
    --text-comment-rgb-value: 99, 94, 105;
    --text-high-contrast-rgb-value: 49, 49, 49;
    --text-medium-contrast-rgb-value: 99, 94, 105;
    --text-low-contrast-rgb-value: 116, 109, 118;
    --detail-high-contrast-rgb-value: 192, 192, 192;
    --detail-medium-contrast-rgb-value: 234, 234, 234;
    --detail-low-contrast-rgb-value: 240, 240, 242;
    --admonition-note-rgb-value: 46, 109, 188;
    --admonition-warning-rgb-value: 255, 196, 9;
    --admonition-danger-rgb-value: 220, 38, 38;
}
.theme-dark {
    --brand-primary: rgb(138, 180, 248);
    --brand-secondary: rgb(193, 168, 226);
    --brand-alternative: rgb(136, 186, 191);
    --background-site: rgb(0, 0, 0);
    --background-code: rgb(12, 12, 12);
    --text-body: rgb(222, 222, 222);
    --text-comment: rgb(170, 170, 170);
    --text-high-contrast: rgb(230, 230, 230);
    --text-medium-contrast: rgb(202, 202, 202);
    --text-low-contrast: rgb(170, 170, 170);
    --detail-high-contrast: rgb(101, 101, 101);
    --detail-medium-contrast: rgb(25, 25, 25);
    --detail-low-contrast: rgb(21, 21, 21);
    --admonition-note: rgb(138, 180, 248);
    --admonition-warning: rgb(253, 186, 116);
    --admonition-danger: rgb(220, 38, 38);
    --brand-primary-rgb-value: 138, 180, 248;
    --brand-secondary-rgb-value: 193, 168, 226;
    --brand-alternative-rgb-value: 136, 186, 191;
    --background-site-rgb-value: 0, 0, 0;
    --background-code-rgb-value: 12, 12, 12;
    --text-body-rgb-value: 222, 222, 222;
    --text-comment-rgb-value: 170, 170, 170;
    --text-high-contrast-rgb-value: 230, 230, 230;
    --text-medium-contrast-rgb-value: 202, 202, 202;
    --text-low-contrast-rgb-value: 170, 170, 170;
    --detail-high-contrast-rgb-value: 101, 101, 101;
    --detail-medium-contrast-rgb-value: 25, 25, 25;
    --detail-low-contrast-rgb-value: 21, 21, 21;
    --admonition-note-rgb-value: 138, 180, 248;
    --admonition-warning-rgb-value: 253, 186, 116;
    --admonition-danger-rgb-value: 220, 38, 38;
}
